import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Text, { H2 } from 'components/atoms/Typography';
import ThemeShape1 from 'images/themeShapes/themeShape_1.inline.svg';
import CloseIcon from 'images/x.inline.svg';
import { colors, breakPoints } from 'utils/variables';
import emptyImage from 'images/emptyImage.jpg';
import Button from 'components/atoms/Button/Button';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.4rem 0;
  width: 30rem;

  @media (min-width: ${breakPoints.tablet}) {
    width: 60rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    justify-content: space-between;
    width: 102.3rem;
  }

  button {
    position: relative;
    display: block;
    z-index: 10;
    margin: 1rem auto 0 auto;
    transition: all 300ms ease;

    @media (min-width: ${breakPoints.desktop}) {
      margin: 5.9rem auto 0 auto;
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 2.4rem 0 1.6rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }
`;

const StyledLeft = styled.div`
  margin: 2rem auto 0 auto;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 auto;
  }

  @media (min-width: ${breakPoints.desktop}) {
    margin: 3.3rem 0 0 1.5rem;
  }

  .part-image {
    width: 30rem;

    @media (min-width: ${breakPoints.desktop}) {
      width: 38.4rem;
      height: 29.7rem;
    }
  }
`;

const StyledRight = styled.div`
  margin: -1.4rem auto 0 auto;
  text-align: center;

  @media (min-width: ${breakPoints.desktop}) {
    margin: -1.4rem 0 0 6rem;
    text-align: left;
  }

  h2 {
    margin-bottom: 0.9rem;
    margin-top: 2rem;

    @media (min-width: ${breakPoints.desktop}) {
      margin-top: 0;
    }
  }
`;

const StyledDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.8rem;

  > span {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.3rem;
    color: ${colors.color2};
  }
`;

const StyledCloseIcon = styled.svg`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 50;
`;

const StyledContactBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: ${({ show }) => (show ? 'auto' : '0')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  margin: ${({ show }) => (show ? '4rem 0 1rem 0' : '0')};
  transition: height 300ms ease, margin 300ms ease, opacity 300ms ease;

  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }
`;

const StyledAddress = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;

  @media (min-width: ${breakPoints.desktop}) {
    text-align: left;
  }

  .text {
    font-size: 1.3rem;
    font-weight: 400;

    @media (min-width: ${breakPoints.desktop}) {
      font-size: 1.6rem;
    }
  }

  .text.firm-name {
    font-weight: 500;
  }

  .email,
  .phone {
    color: ${colors.color1};
    display: block;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1rem;

    @media (min-width: ${breakPoints.desktop}) {
      font-size: 1.6rem;
    }
  }
`;

const StyledLinkText = styled.a`
  color: ${colors.color2};
  font-weight: 400;
  font-size: 1.3rem;
  border: 0;
  text-decoration: none;
  display: block;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.5rem;
    display: inline-block;
  }

  &:hover {
    border: 0;
    color: ${colors.color1};
  }
`;

const getDescription = (description, index) => (
  <span key={`${description.name}-${index}`}>
    <b>{description.name}:</b> {description.value}
  </span>
);

const PartModalContent = ({ part, closeModal }) => {
  const { image, title, description } = part;
  const [showContactBox, setShowContactBox] = useState(false);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return (
    <StyledWrapper>
      <StyledCloseIcon as={CloseIcon} onClick={closeModal} />
      <StyledContent>
        <StyledLeft>
          {image.childImageSharp ? (
            <Img className="part-image" fluid={image.childImageSharp.fluid} alt={title} />
          ) : (
            <img className="part-image" src={emptyImage} alt="empty" />
          )}
        </StyledLeft>
        <StyledRight>
          <H2>{title}</H2>
          <ThemeShape1 />
          <StyledDescription>{description.map(getDescription)}</StyledDescription>
        </StyledRight>
      </StyledContent>
      <Button onClick={() => setShowContactBox(!showContactBox)}>{showContactBox ? 'Ukryj dane kontaktowe' : 'Zapytaj o dostępność'}</Button>
      <StyledContactBox show={showContactBox}>
        <StyledAddress>
          <Text color={colors.color1} className="firm-name">
            KUBECZEK Auto-Części
          </Text>
          <Text color={colors.color2}>
            Ul. Wodzisławska 100
            <br />
            44-218 Rybnik
          </Text>
        </StyledAddress>
        <StyledAddress>
          <span className="email">
            E-mail:&nbsp;
            <StyledLinkText href="mailto: kontakt@serwis-kubeczek.pl">&nbsp;kontakt@serwis-kubeczek.pl</StyledLinkText>
          </span>
          <span className="phone">
            Numer telefonu:&nbsp;
            <StyledLinkText href="tel: 504865436">&nbsp;504 865 436;</StyledLinkText>
            <StyledLinkText href="tel: 324249242">&nbsp;32 4249242</StyledLinkText>
          </span>
        </StyledAddress>
      </StyledContactBox>
    </StyledWrapper>
  );
};

PartModalContent.propTypes = {
  part: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    title: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default PartModalContent;
